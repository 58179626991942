import React from "react"
import "./footer.scss"

const Footer = () => (
  <footer>
    © Copyright 2019 Guillermo Maschwitz
  </footer>
)

export default Footer
