import { CodeWave } from "gatsby-theme-waves";
import Code from "components/code";
import ContactLink from "components/contact-link";
import "prismjs/components/prism-docker";
import "prismjs/components/prism-ini";
import "prismjs/components/prism-json";
import React from 'react';
export default {
  CodeWave,
  Code,
  ContactLink,
  React
};